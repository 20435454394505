@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TATSanachon';
  src: local('TATSanachon'), url('./fonts/TATSanaChon-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TATSanachon';
  src: local('TATSanachon'), url('./fonts/TATSanaChon-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'TATSanachon';
  font-weight: bold;
}

.ci-text {
  font-family: 'TATSanachon';
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background-color: #000
}

@layer components {
  .button {
    font-family: 'TATSanachon';
    @apply px-4 py-1 rounded-md text-black h-10 font-bold flex items-center justify-center border-2 border-black border-b-4 hover:border-b-2 hover:bg-slate-400 duration-100 ease-out leading-none;
  }

  .button.sm {
    @apply h-8 px-1 py-0 leading-none text-xs;
  }
  
  .button.primary {
    @apply bg-tat-blue text-white border-tat-dark-blue hover:bg-tat-dark-blue;
  }
  
  .button.secondary {
    @apply bg-white text-tat-dark-blue border-tat-dark-blue hover:bg-slate-200 border-b-2;
  }
  
  .landing-warpper {
    @apply flex flex-col pt-8 pb-4 px-4 lg:pt-20 lg:pb-6 lg:px-0 min-h-screen w-screen bg-white bg-[url('./img/landing-bg.jpg')] bg-cover;
  }

  .main-content {
    @apply flex flex-col flex-1;
  }

  .footer-note {
    @apply flex flex-col items-center justify-center text-center;
  }

  .footer-note p {
    font-family: 'TATSanachon';
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    @apply text-sm text-white;
  }

  .page-title {
    @apply mb-8 lg:mb-10 text-center text-xl lg:text-2xl font-bold text-black leading-normal;
    text-shadow: -1px -1px 0 white,  
    1px -1px 0 white,
    -1px 1px 0 white,
     1px 1px 0 white;
  }

  .map-wrapper {
    @apply flex flex-col min-h-screen w-screen bg-[#F8F5F2];
  }

  .topbar {
    @apply basis-[80px] min-w-0 bg-white py-3 pl-2 pr-4 flex flex-row items-center justify-between border-b border-black/10;
  }

  .topbar .logo img {
    @apply h-12 max-w-full lg:h-14;
  }

  .map-container {
    @apply flex-1 min-w-0 relative outline-none;
  }

  .map-block{
    @apply top-0 left-0 absolute z-10 w-full h-full outline-none;
  }

  .infowindow-wrapper {
    @apply flex flex-row items-start justify-start gap-x-2;
  }

  .place-cover {
    @apply basis-[96px] min-h-[130px] rounded min-w-0 bg-slate-400 self-stretch;
  }

  .place-info {
    @apply flex flex-1 w-full min-w-0 flex-col items-start justify-between gap-y-2 self-stretch;
  }

  .place-info h3 {
    @apply text-base font-bold line-clamp-2 leading-tight mb-1 text-left;
  }

  .place-info h3 button {
    @apply text-black hover:text-tat-dark-blue bg-transparent border-none outline-none appearance-none cursor-pointer justify-start items-start text-left;
  }
  
  .place-info h4 {
    @apply text-sm font-normal line-clamp-1 leading-tight mb-0 text-tat-blue; 
  } 

  .place-info p {
    font-family: 'TATSanachon';
    @apply text-sm font-normal line-clamp-2 leading-snug !mb-2 !mt-0 text-black/50;
  }


}