.leaflet-popup {
  margin-bottom: 32px;
}

.leaflet-popup-content-wrapper {
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #cecece;
  padding: 8px;
  border-radius: 8px;
}

.leaflet-popup-tip {
  border-radius: 2px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.leaflet-popup-content-wrapper, .leaflet-popup-content {
  margin: 0;
}


.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  /* background-color: rgba(17, 96, 167, 1); */
  /* color: white; */
  font-family: 'TATSanachon';
  line-height: 1;
  font-size: 14px;
}